import React from "react";
import { Helmet } from "react-helmet";
import RehypeReact from "rehype-react";
import { graphql } from "gatsby";
import Container from "../components/Container";
import { Title, Text, Paragraph } from "../components/typography";
import page from "../components/page";
import { pY } from "../components/spacingShorthands";
import Cta from "../components/Cta";
import HeaderMenu from "../components/HeaderMenu";

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    h2: (props) => (
      <Title css={{ marginTop: "48px !important" }} level={3} {...props} />
    ),
    h3: (props) => (
      <Title css={{ marginTop: "36px !important" }} level={4} {...props} />
    ),
    p: Paragraph,
  },
}).Compiler;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        createdOn
        title
      }
    }
  }
`;

export default page(
  ({
    data: {
      markdownRemark: {
        htmlAst,
        frontmatter: { createdOn, title },
      },
    },
  }) => (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container css={pY(96)}>
        <Title>{title}</Title>
        <Paragraph>
          <Text type="secondary">on {new Date(createdOn).toDateString()}</Text>
        </Paragraph>
        <div css={{ marginTop: 48 }}>{renderAst(htmlAst)}</div>
      </Container>
      <Cta textAlign="left" />
    </div>
  ),
  { HeaderMenu }
);
